// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-page-js": () => import("/opt/build/repo/src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("/opt/build/repo/src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-nl-js": () => import("/opt/build/repo/src/pages/about/index.nl.js" /* webpackChunkName: "component---src-pages-about-index-nl-js" */),
  "component---src-pages-blog-index-en-js": () => import("/opt/build/repo/src/pages/blog/index.en.js" /* webpackChunkName: "component---src-pages-blog-index-en-js" */),
  "component---src-pages-blog-index-nl-js": () => import("/opt/build/repo/src/pages/blog/index.nl.js" /* webpackChunkName: "component---src-pages-blog-index-nl-js" */),
  "component---src-pages-contact-index-en-js": () => import("/opt/build/repo/src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-nl-js": () => import("/opt/build/repo/src/pages/contact/index.nl.js" /* webpackChunkName: "component---src-pages-contact-index-nl-js" */),
  "component---src-pages-index-en-js": () => import("/opt/build/repo/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-nl-js": () => import("/opt/build/repo/src/pages/index.nl.js" /* webpackChunkName: "component---src-pages-index-nl-js" */),
  "component---src-pages-tags-index-en-js": () => import("/opt/build/repo/src/pages/tags/index.en.js" /* webpackChunkName: "component---src-pages-tags-index-en-js" */),
  "component---src-pages-tags-index-nl-js": () => import("/opt/build/repo/src/pages/tags/index.nl.js" /* webpackChunkName: "component---src-pages-tags-index-nl-js" */)
}

